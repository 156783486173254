import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../helpers";

import { GraphQLError } from "../components/elements";
import Layout from "../containers";
import GenericPostPreview from "./GenericPostPreview";

export const query = graphql`
  query GenrePageQuery($genre: String) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        genres: { elemMatch: { title: { in: [$genre] } } }
        isBackgroundPost: { ne: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tags {
            tag {
              title
            }
          }
        }
      }
    }
    details: sanityGenre(title: { eq: $genre }) {
      title
      description
      keywords
    }
  }
`;

const GenrePage = (props) => {
  const { data, errors, pageContext } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }

  const postNodes =
    data &&
    data.posts &&
    mapEdgesToNodes(data.posts).filter((post) => post.tags.length === 0);
  const { keywords, description } = data && data.details;
  const { genre } = pageContext;

  return (
    <GenericPostPreview
      title={genre}
      postNodes={postNodes}
      keywords={keywords}
      description={description}
    />
  );
};

export default GenrePage;
